canvas {
  height: 100vh;
  width: 100vw;
  display: block;
}

body {
  margin: 0;
}

select {
  position: absolute;
  top: 0;
  right: 0;
}